import { UserHookState } from '@/lib/supabase/browser';
import { ArrowRight } from 'lucide-react';
import { FairLogoText } from '../fair-logo';
import { LetsSetUpYourAccount } from './steps/account-setup';
import { BillingAccounts } from './steps/billing-accounts';
import { ClaimFreeCredits } from './steps/free-credits';
import { WhatIsFair } from './steps/what-is-fair';
export type OnboardingContentProps = {
  user: NonNullable<UserHookState['data']>;
  updateUser: UserHookState['update'];
  moveToNextStep: (save?: boolean) => void;
};
export const ONBOARDING_STEPS: {
  id: string;
  title: React.ReactNode;
  description?: React.ReactNode;
  content?: React.FC<OnboardingContentProps>;
  buttonContent?: React.ReactNode;
}[] = [{
  id: 'welcome_to_fair_1.0',
  title: <>
        Welcome to <FairLogoText />!
      </>,
  description: <>The cheapest way to use state-of-the art AI models.</>,
  buttonContent: <>
        <span>
          What is <FairLogoText className="font-bold" />?
        </span>
        <ArrowRight className="size-4 shrink-0" />
      </>
}, {
  id: 'what_is_fair_1.0',
  title: <>
        What is <FairLogoText />?
      </>,
  description: <>
        <FairLogoText className="font-bold" /> aims to replace the current AI
        chatbots with 2 simple features:
      </>,
  content: WhatIsFair,
  buttonContent: <>
        Set up your account
        <ArrowRight className="size-4 shrink-0" />
      </>
}, {
  id: 'account_setup_1.0',
  title: "Let's set up your account",
  content: LetsSetUpYourAccount
}, {
  id: 'billing_accounts_1.0',
  title: 'Your billing account',
  description: <>
        When using <FairLogoText className="inline-block" />, you&apos;ll spend
        credits that are associated with a billing account.
      </>,
  content: BillingAccounts,
  buttonContent: <>
        Continue
        <ArrowRight className="ml-2 size-4 shrink-0" />
      </>
}, {
  id: 'free_starting_credits_1.0',
  title: 'Penny for your thoughts? How about 50¢?',
  description: <>
        To get you started, we are giving you{' '}
        <span className="rounded-lg bg-green-500 px-2 py-1 font-mono font-bold text-white">
          $0.50
        </span>{' '}
        in{' '}
        <span className="font-semibold italic text-green-500">
          free credits{' '}
        </span>
        to see how cheap AI can be.
      </>,
  content: ClaimFreeCredits
}] as const;