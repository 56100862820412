'use client';

import { FairLogoText } from '@/components/fair-logo';
import { Bot, Coins } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../ui/card';
export function WhatIsFair() {
  return <div className="grid gap-4 sm:grid-cols-2" data-sentry-component="WhatIsFair" data-sentry-source-file="what-is-fair.tsx">
      <Card data-sentry-element="Card" data-sentry-source-file="what-is-fair.tsx">
        <CardHeader className="flex flex-row items-center gap-2 space-y-0" data-sentry-element="CardHeader" data-sentry-source-file="what-is-fair.tsx">
          <Coins className="size-5 shrink-0" data-sentry-element="Coins" data-sentry-source-file="what-is-fair.tsx" />
          <CardTitle data-sentry-element="CardTitle" data-sentry-source-file="what-is-fair.tsx">Pay-as-you-go pricing</CardTitle>
        </CardHeader>
        <CardContent data-sentry-element="CardContent" data-sentry-source-file="what-is-fair.tsx">
          <CardDescription data-sentry-element="CardDescription" data-sentry-source-file="what-is-fair.tsx">
            Buy credits and use them as you chat. Average ChatGPT user spends
            less than $2/month using <FairLogoText data-sentry-element="FairLogoText" data-sentry-source-file="what-is-fair.tsx" />.
          </CardDescription>
        </CardContent>
      </Card>
      <Card data-sentry-element="Card" data-sentry-source-file="what-is-fair.tsx">
        <CardHeader className="flex flex-row items-center gap-2 space-y-0" data-sentry-element="CardHeader" data-sentry-source-file="what-is-fair.tsx">
          <Bot className="size-5 shrink-0" data-sentry-element="Bot" data-sentry-source-file="what-is-fair.tsx" />
          <CardTitle data-sentry-element="CardTitle" data-sentry-source-file="what-is-fair.tsx">Multiple Models</CardTitle>
        </CardHeader>
        <CardContent data-sentry-element="CardContent" data-sentry-source-file="what-is-fair.tsx">
          <CardDescription data-sentry-element="CardDescription" data-sentry-source-file="what-is-fair.tsx">
            Explore and interact with various language models. No more choosing
            which subscription to buy!
          </CardDescription>
        </CardContent>
      </Card>
    </div>;
}