'use client';

import { useCallback, useEffect, useState } from 'react';
import { useIsMobile } from './use-is-mobile';

// TODO: Use cookies to store the state so it is accessible on the server
// while pre-rendering
export function useExpandableElement(key: string) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useIsMobile();
  const toggleIsExpanded = useCallback(() => {
    setIsExpanded(value => {
      const newState = !value;
      localStorage.setItem(key, JSON.stringify(newState));
      return newState;
    });
  }, [key]);

  // On mount, get the value from localStorage and add a listener for resize events
  useEffect(() => {
    if (!isMobile) {
      setIsExpanded(true);
    }
    if (isMobile && setIsExpanded) {
      setIsExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  // Handle opening and closing on screen size changes
  // Needs to be defined after the resize handler to overwrite it on load
  useEffect(() => {
    const value = localStorage.getItem(key);
    if (value === 'true' || value === null && window.innerWidth >= 768) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [key]);
  return {
    isExpanded,
    toggleIsExpanded,
    isMobile
  };
}