'use client';

import { fetchChats, SupabaseChats } from '@/app/(chat)/Sidebar/fetch-chats-browser';
import { captureException } from '@sentry/nextjs';
import * as React from 'react';
import { useState } from 'react';
import { toast } from 'sonner';
import { useImmer } from 'use-immer';
import { createBrowserSupabase } from '../supabase/browser';
import { fetchUser } from '../supabase/queries';
import { useExpandableElement } from './use-expandable-element';
const LOCAL_STORAGE_KEY = 'sidebar';
interface SidebarContext {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  isLoading: boolean;
  isMobile: boolean;
  chats: SupabaseChats;
  setInitialChats: (chats: SupabaseChats) => void;
  refreshChats: () => void;
}
const SidebarContext = React.createContext<SidebarContext | undefined>(undefined);
export function useSidebar() {
  const context = React.useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebarContext must be used within a SidebarProvider');
  }
  return context;
}
interface SidebarProviderProps {
  children: React.ReactNode;
}
export function SidebarProvider({
  children
}: SidebarProviderProps) {
  const [isLoading, setLoading] = useState(true);
  const [chats, setChats] = useImmer<SupabaseChats>([]);
  const {
    isExpanded: isSidebarOpen,
    toggleIsExpanded: toggleSidebar,
    isMobile
  } = useExpandableElement(LOCAL_STORAGE_KEY);
  const setInitialChats = React.useCallback((chats: SupabaseChats) => {
    setChats(chats);
    setLoading(false);
  }, [setChats]);
  const refreshChats = React.useCallback(async () => {
    await fetchChats((await fetchUser(createBrowserSupabase()))?.id).then(res => {
      if (res.error) {
        console.error(res.error);
        captureException(res.error);
        toast.error('There was an error fetching your chats');
        return;
      }
      setChats(res.data);
    });
  }, [setChats]);
  return <SidebarContext.Provider value={{
    isSidebarOpen,
    toggleSidebar,
    isLoading,
    isMobile,
    chats,
    setInitialChats,
    refreshChats
  }} data-sentry-element="unknown" data-sentry-component="SidebarProvider" data-sentry-source-file="use-sidebar.tsx">
      {children}
    </SidebarContext.Provider>;
}