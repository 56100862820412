import { Info } from 'lucide-react';
import { OnboardingContentProps } from '../config';
export function BillingAccounts({
  user,
  moveToNextStep
}: OnboardingContentProps) {
  return <>
      <p className="text-sm text-muted-foreground">
        We&apos;ve created an initial billing account for you. You can create
        additional accounts and invite team members for centralized billing.
      </p>
      <div className="space-y-3">
        {user.user_billing_accounts.map((billingAccount, index) => <div key={index} className="rounded-lg border border-gray-200 p-4 shadow">
            <h4 className="font-medium">
              {billingAccount.billing_account?.name}
            </h4>
            <p className="mt-1 text-xs text-muted-foreground">
              Default Account
            </p>
          </div>)}
      </div>
      <p className="text-muted-foreground">
        <Info className="me-2 inline size-3.5 shrink-0" data-sentry-element="Info" data-sentry-source-file="billing-accounts.tsx" />
        You can manage your billing accounts in your account settings.
      </p>
    </>;
}