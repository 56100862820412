import { LoaderCircle, MessageCircle } from 'lucide-react';
import { useTransition } from 'react';
import { toast } from 'sonner';
import { Button } from '../../ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui/select';
import { createStartingBonusTransaction } from '../actions';
import { OnboardingContentProps } from '../config';
export function ClaimFreeCredits({
  user,
  updateUser,
  moveToNextStep
}: OnboardingContentProps) {
  const [isSubmitting, startSubmition] = useTransition();
  return <>
      <p className="text-muted-foreground">
        It might not seem like much, but you will be surprised, just how cheap
        AI can be!
      </p>
      <div className="space-y-2">
        <p className="text-sm text-muted-foreground">
          The billing account to add the credits to:
        </p>
        <Select defaultValue={user.user_billing_accounts[0].billing_account_id} data-sentry-element="Select" data-sentry-source-file="free-credits.tsx">
          <SelectTrigger className="w-full" data-sentry-element="SelectTrigger" data-sentry-source-file="free-credits.tsx">
            <SelectValue placeholder="Theme" data-sentry-element="SelectValue" data-sentry-source-file="free-credits.tsx" />
          </SelectTrigger>
          <SelectContent data-sentry-element="SelectContent" data-sentry-source-file="free-credits.tsx">
            {user.user_billing_accounts.map(ba => <SelectItem key={ba.billing_account_id + ba.user_id} value={ba.billing_account_id}>
                {ba.billing_account?.name}
              </SelectItem>)}
          </SelectContent>
        </Select>
      </div>
      <Button disabled={isSubmitting} className="mt-auto rounded-xl text-lg" onClick={() => startSubmition(async () => {
      const error = await createStartingBonusTransaction(user.id, user.user_billing_accounts[0].billing_account_id);
      if (error) {
        toast.error('Failed to create bonus transaction');
        return;
      }
      moveToNextStep();
    })} data-sentry-element="Button" data-sentry-source-file="free-credits.tsx">
        Start chatting!
        {isSubmitting ? <LoaderCircle strokeWidth={1} className="size-4 shrink-0 animate-spin" /> : <MessageCircle className="size-4 shrink-0" />}
      </Button>
    </>;
}