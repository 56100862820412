'use client';

import * as React from 'react';
import { useExpandableElement } from './use-expandable-element';
interface ChatDetailContext {
  isChatDetailOpen: boolean;
  toggleChatDetail: () => void;
  isMobile: boolean;
}
const ChatDetailContext = React.createContext<ChatDetailContext | undefined>(undefined);
export function useChatDetail() {
  const context = React.useContext(ChatDetailContext);
  if (!context) {
    throw new Error('useChatDetail must be used within a ChatDetailProvider');
  }
  return context;
}
interface ChatDetailProviderProps {
  children: React.ReactNode;
}
export function ChatDetailProvider({
  children
}: ChatDetailProviderProps) {
  const {
    isExpanded: isChatDetailOpen,
    toggleIsExpanded: toggleChatDetail,
    isMobile
  } = useExpandableElement('chat-detail');
  return <ChatDetailContext.Provider value={{
    isChatDetailOpen,
    toggleChatDetail,
    isMobile
  }} data-sentry-element="unknown" data-sentry-component="ChatDetailProvider" data-sentry-source-file="use-chat-details.tsx">
      {children}
    </ChatDetailContext.Provider>;
}